{/**

I know what you think.
I can hear you when you're asking "Why did you do that?"...
Actually i don't know either.
It was the best idea to replicate custom domains of old version,
because our page indexes has already done in google 
when i came in.

*/}

export const urlOf = {
    "property": "/en/properties",
    "residential": {
        "rent": "/en/rent/properties-for-rent",
        "buy": "/en/sale/properties-for-sale",
    },
    "luxury": {
        "buy": "/en/sale/luxury-properties-for-sale",
        "rent": "/en/rent/luxury-properties-for-rent",
    },
    "commercial": {
        "buy": "/en/sale/commercial-properties-for-sale",
        "rent": "/en/rent/commercial-properties-for-rent",
    },
    "guides": {
        "list": "/en/guides",
        "single": "/en/guides/:slug",
    },
    "blog": {
        "list": "/en/blog",
        "single": "/en/blog/:slug",
    },
    "user": {
        "favorites": "/en/favorites",
        "profile": "/en/profile",
        "preferences": "/en/preferences",
    },
    "search": "/en/search" || "/test",
    "static": {
        "about": "/en/about-us",
        "zeekeezForBrokers": "/en/brokers/zeekeez-pricing",
        "zeekeezforDevelopers": "/en/developers/zeekeez-pricing",
        "propertyHunting": "/en/property-hunter",
        "mortgage": "/en/mortgages",
        "contact": "/en/contact",
        "career": "/en/career",
        "crm": "/en/real-estate-crm",
    },
    "legal": {
        "privacyPolicy": "/en/privacy-policy",
        "termsAndConditions": "/en/terms-and-conditions"
    },
    "socials": {
        "facebook": "",
        "instagram": "",
        "linkedin": "",
        "twitter": ""
    }
}