import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

export default function NavLink(props) {

    const router = useRouter();
    const path = router.asPath;

    let className = props.className;
    if (path === props.href) {
        className = props.className + " active";
    }

    if (path === "/") {
        return (
            <>
                <Link className={className} href={props.href} title={props.title} rel={props.rel}>
                    {props.children}
                </Link>
            </>
        )
    }
    else {
        return (
            <>
                <div className={className} onClick={() => { router.push(props.href) }} title={props.title} rel={props.rel} style={{ cursor: "pointer" }}>
                    {props.children}
                </div>
            </>
        )
    }
}
