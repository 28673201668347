import { urlOf } from "../../dictionaries/customUrl";
import Image from "next/image";

export default function Footers() {
  return (
    <>
      <footer className="text-white">
        <div className="container py-4 py-lg-5">
          <div className="row justify-content-center">
            <div className="col-sm-4 col-md-3 text-center text-lg-start d-flex flex-column item">
              <p className="fs-6 text-white bold">Hub Professionals</p>
              <ul className="list-unstyled">
                <li>
                  <a
                    title={"Zeekeez for Real Estate Brokers"}
                    className="link-light"
                    href={urlOf.static.zeekeezForBrokers}
                  >
                    Zeekeez For Brokers
                  </a>
                </li>
                <li>
                  <a
                    title={"Zeekeez for Real Estate Developers"}
                    className="link-light"
                    href={urlOf.static.zeekeezforDevelopers}
                  >
                    Zeekeez For Developers
                  </a>
                </li>
                <li>
                  <a
                    title={"Zeekeez for Real Estate Developers"}
                    className="link-light"
                    href={urlOf.static.crm}
                  >
                    Zeekeez Real Estate CRM
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-4 col-md-3 text-center text-lg-start d-flex flex-column item">
              <p className="fs-6 text-white bold">Property Portal</p>
              <ul className="list-unstyled">
                <li>
                  <a
                    title={"About UAE's Property Portal"}
                    className="link-light"
                    href={urlOf.static.about}
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    title={"Contact UAE's Property Website"}
                    className="link-light"
                    href={urlOf.static.contact}
                  >
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    title={"UAE Proptech in Dubai"}
                    className="link-light"
                    href={urlOf.static.career}
                  >
                    Career
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-4 col-md-3 text-center text-lg-start d-flex flex-column item">
              <p className="fs-6 text-white bold">Quick Links</p>
              <ul className="list-unstyled">
                <li>
                  <a
                    title={"Zeekeez for Real Estate Property Hunting"}
                    className="link-light"
                    href={urlOf.static.mortgage}
                  >
                    Mortgages
                  </a>
                </li>
                <li>
                  <a
                    title={"Zeekeez for Real Estate Property Hunting"}
                    className="link-light"
                    href={urlOf.static.propertyHunting}
                  >
                    Property Hunting
                  </a>
                </li>
                <li>
                  <a
                    title={"UAE's Property Marketplace Terms & Conditions"}
                    className="link-light"
                    href={urlOf.legal.termsAndConditions}
                  >
                    Terms and Conditions
                  </a>
                </li>
                <li>
                  <a
                    title={"UAE's Property Marketplace Privacy Policy"}
                    className="link-light"
                    href={urlOf.legal.privacyPolicy}
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 text-center text-lg-start d-flex flex-column align-items-center order-first align-items-lg-start order-lg-last item social">
              <div className="fw-bold d-flex align-items-center mb-2">
                <Image
                  src="/assets/img/zeekeez-logo-white.svg"
                  alt="Zeekeez Logo White"
                  title="Zeekeez Logo White"
                  width={236}
                  height={59}
                  style={{ maxWidth: "8rem" }}
                />
              </div>
              <p className="text-footer copyright">
                Zeekeez apps available soon!
              </p>
              <div className="d-flex gap-2 mb-lg-0 mb-3">
                <a title={"Zeekeez on Google Play Store"} href="#">
                  <Image
                    src="/assets/img/playstorebadge.svg"
                    alt="Zeekeez on Google Play Store"
                    title="Zeekeez on Google Play Store"
                    width={135}
                    height={40}
                    className="img-fluid"
                  />
                </a>
                <a title={"Zeekeez on App Store"} href="#">
                  <Image
                    src="/assets/img/appstorebadge.svg"
                    alt="Zeekeez on App Store"
                    title="Zeekeez on App Store"
                    width={120}
                    height={40}
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between align-items-center pt-3">
            <p className="mb-0">Copyright ©Zeekeez 2019-2023</p>
            <ul className="list-inline mb-0 socialiconsfooter">
              <li className="list-inline-item">
                <a title={"Zeekeez Facebook"} href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icon-tabler-brand-facebook"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                  </svg>
                </a>
              </li>
              <li className="list-inline-item">
                <a title={"Zeekeez Instagram"} href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icon-tabler-brand-instagram"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x={4} y={4} width={16} height={16} rx={4} />
                    <circle cx={12} cy={12} r={3} />
                    <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
                  </svg>
                </a>
              </li>
              <li className="list-inline-item">
                <a title={"Zeekeez Linkedin"} href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icon-tabler-brand-linkedin"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x={4} y={4} width={16} height={16} rx={2} />
                    <line x1={8} y1={11} x2={8} y2={16} />
                    <line x1={8} y1={8} x2={8} y2="8.01" />
                    <line x1={12} y1={16} x2={12} y2={11} />
                    <path d="M16 16v-3a2 2 0 0 0 -4 0" />
                  </svg>
                </a>
              </li>
              <li className="list-inline-item">
                <a title={"Zeekeez Twitter"} href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icon-tabler-brand-twitter"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}
