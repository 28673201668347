import Layout from "@/components/_search/_layout";
import { store } from "redux/store/property";
import { Provider } from "react-redux";
import "../../public/assets/css/aos.min.css";
import ReactGA from "react-ga";
import React, { useEffect } from "react";
import { checkSession } from "@/helpers/sessionHelper";
import { SessionProvider } from "next-auth/react";
import "bootstrap/dist/css/bootstrap.css";
import "aos/dist/aos.css";
import "../../public/assets/css/searchengine.css";
import "../../public/assets/css/benimozelnavbar.css";
import "../../public/assets/css/bottomnavbar.css";
import "../../public/assets/css/Footer-Dark-Multi-Column-icons.css";
import "../../public/assets/css/styles.css";
import "../../public/assets/css/toggleeeeee.css";
import "../../public/assets/css/Gilroy-Bold.css";
import "../../public/assets/css/Gilroy-Heavy.css";
import "../../public/assets/css/Gilroy-Light.css";
import "../../public/assets/css/Gilroy-Medium.css";
import "../../public/assets/css/Gilroy-Regular.css";

export default function App({ Component, pageProps }) {
  const clientId =
    "78277229480-f6fgm65k8a2s0snhngnn7nmkmns2i6qi.apps.googleusercontent.com";
  const TRACKING_ID = "UA-165874994-1";

  useEffect(() => {
    let sessionStatus = checkSession();

    if (!sessionStatus) {
      try {
        ReactGA.initialize(TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
      } catch (error) {}

      try {
        const gapi = import("gapi-script")
          .then((pack) => pack.gapi)
          .then((d) => {
            try {
              d.load("client:auth2", initClient());
            } catch (error) {}
          });

        const initClient = async () => {
          try {
            const d = await gapi;
            d.client.init(
              {
                clientId: clientId,
                scope: "email",
              },
              500
            );
          } catch (error) {}
        };
      } catch (error) {}
    }
  }, []);
  useEffect(() => {
    // Check if we're running on the client side before trying to register the service worker.
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("service-worker.js") // Specify the path to your service worker file
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }
  }, []);
  return (
    <React.StrictMode>
      <SessionProvider session={pageProps.session}>
        <Provider store={store}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </Provider>
      </SessionProvider>
    </React.StrictMode>
  );
}
