import { urlOf } from "../../dictionaries/customUrl";
import Cookies from "universal-cookie";
import mobileMenuopen from "@/helpers/uiHelpers/mobileMenuOpen";
import NavLink from "../_NavLink/NavLink";
import { useRouter } from "next/router";
import { useEffect } from "react";

export default function MobileMenu() {
  const cookies = new Cookies();
  const router = useRouter();

  useEffect(() => {
    if (document.getElementById("ortanavlinkk").classList.contains("active")) {
      mobileMenuopen();
    }
  }, [router.query, router.asPath]);

  return (
    <>
      <div className="fixed-bottom d-lg-none">
        <nav className="navbar navbar-light navbar-expand fixed-bottom bottomnavbar">
          <div className="container-fluid">
            <ul
              className="navbar-nav border rounded-0 fixed-bottom"
              style={{ background: "var(--bs-white)" }}
            >
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  href="/"
                  title={"UAE's Property Portal | Zeekeez"}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icon-tabler-smart-home"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M19 8.71l-5.333 -4.148a2.666 2.666 0 0 0 -3.274 0l-5.334 4.148a2.665 2.665 0 0 0 -1.029 2.105v7.2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-7.2c0 -.823 -.38 -1.6 -1.03 -2.105" />
                    <path d="M16 15c-2.21 1.333-5.792 1.333-8 0" />
                  </svg>
                  <p className="mb-0">Home</p>
                </NavLink>
              </li>
              <li className="nav-item">
                {" "}
                <a
                  className="nav-link"
                  onClick={() => {
                    if (cookies.get("_zID")) {
                      router.push("en/favorites");
                    } else {
                      document.getElementById("loginButton").click();
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icon-tabler-heart"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
                  </svg>
                  <p className="mb-0">Favorites</p>
                </a>
              </li>
              <li className="nav-item ortanavitem">
                <a
                  className="nav-link categoriesnavitem"
                  id="ortanavlinkk"
                  onClick={() => {
                    mobileMenuopen();
                  }}
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-grid-fill"
                  >
                    <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z" />
                  </svg>
                </a>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" href={urlOf.blog.list}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icon-tabler-paperclip"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M15 7l-6.5 6.5a1.5 1.5 0 0 0 3 3l6.5 -6.5a3 3 0 0 0 -6 -6l-6.5 6.5a4.5 4.5 0 0 0 9 9l6.5 -6.5" />
                  </svg>
                  <p className="mb-0">Blog</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" href={urlOf.guides.list}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icon-tabler-route"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx={6} cy={19} r={2} />
                    <circle cx={18} cy={5} r={2} />
                    <path d="M12 19h4.5a3.5 3.5 0 0 0 0 -7h-8a3.5 3.5 0 0 1 0 -7h3.5" />
                  </svg>
                  <p className="mb-0">Guides</p>
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div
        id="mymenu"
        className="categoriesmobilemenu"
        style={{
          width: "2rem",
          bottom: "0%",
          height: "2.5rem",
          borderRadius: "50%",
        }}
      >
        <ul className="list-unstyled hide" id="mymenulist">
          <li>
            <NavLink href={urlOf.residential.buy}>
              <span className="gilroybold">Buy</span>&nbsp;Properties{" "}
            </NavLink>
          </li>
          <li>
            <NavLink href={urlOf.residential.rent}>
              <span className="gilroybold">Rent</span>&nbsp;Properties{" "}
            </NavLink>
          </li>
          <li>
            <div>
              <hr className="hr-small" />
            </div>
          </li>
          <li>
            <NavLink href={urlOf.luxury.buy}>
              <span className="gilroybold">Buy</span>&nbsp;Luxury Properties{" "}
            </NavLink>
          </li>
          <li>
            <NavLink href={urlOf.luxury.rent}>
              <span className="gilroybold">Rent</span>&nbsp;Luxury Properties{" "}
            </NavLink>
          </li>
          <li>
            <div>
              <hr className="hr-small" />
            </div>
          </li>
          <li>
            <NavLink href={urlOf.commercial.buy}>
              <span className="gilroybold">Buy</span>&nbsp;Commercial{" "}
            </NavLink>
          </li>
          <li>
            <NavLink href={urlOf.commercial.rent}>
              <span className="gilroybold">Rent</span>&nbsp;Commercial{" "}
            </NavLink>
          </li>
          <li>
            <div>
              <hr className="hr-small" />
            </div>
          </li>
          <li>
            <NavLink href={urlOf.static.mortgage}>
              <span className="gilroybold">Mortgages</span>{" "}
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
}
