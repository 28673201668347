import Cookies from "universal-cookie";

/**
 * Vérifie si une session est active en vérifiant l'existence d'un cookie '_zID'.
 * Si le cookie existe, ajoute la classe 'logged' à l'élément 'body'.
 * Doit être exécutée côté client uniquement en raison de la manipulation du DOM.
 * @returns {boolean} - retourne 'true' si la session est active, 'false' sinon.
 */
export const checkSession = () => {
    const cookies = new Cookies();
    const token = cookies.get("_zID");

    if (token) {
        document.body.classList.add("logged");
        return true;
    }
    return false;
};

/**
 * Récupère le token de la session à partir d'un cookie '_zID'.
 * Peut être exécuté à la fois côté client et côté serveur.
 * @param {object} [req] - l'objet 'request' dans le contexte de Next.js, nécessaire pour SSR.
 * @returns {object} - retourne un objet indiquant si la session est active et le token, si existant.
 */
export const getSession = (req) => {
    const cookies = new Cookies(req ? req.headers.cookie : undefined);
    const token = cookies.get('_zID');

    return token
        ? { session: true, token }
        : { session: false, token: null };
};

/**
 * Définit un cookie '_zID' pour une session avec le token fourni.
 * @param {string} token - Le token à définir pour la session.
 * @param {boolean} [remember=false] - Indique si le cookie doit persister pendant 7 jours. Sinon, il dure 1 heure.
 * @returns {object} - retourne un objet indiquant que la session est active et le token défini.
 */
export const setSession = (token, remember = false) => {
    const cookies = new Cookies();
    const maxage = remember ? 86400 * 7 : 3600;

    cookies.set("_zID", token, { path: '/', maxAge: maxage });

    return { session: true, token: cookies.get("_zID") };
};

/**
 * Supprime le cookie '_zID', met fin à la session et retire la classe 'logged' de l'élément 'body'.
 * Doit être exécutée côté client uniquement en raison de la manipulation du DOM.
 * @returns {boolean} - retourne toujours 'true'.
 */
export const killSession = () => {
    const cookies = new Cookies();
    document.body.classList.remove("logged");
    cookies.remove("_zID");

    return true;
};
