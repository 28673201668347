import { createSlice } from '@reduxjs/toolkit';

export const propertySlice = createSlice({
    name: 'property',
    initialState: {
        businessType: "rent",
        propertyTypes: null,
        priceMin: 0,
        priceMax: 0,
    },
    reducers: {
        increment: state => {
            state.propertyTypes += 1;
        },
        decrement: state => {
            state.propertyTypes -= 1;
        },
        bulkUpdate: (state, action) => {
            state.propertyTypes += action.payload;
        },
        reset: state => {
            state.propertyTypes = 0;
        }
    }
})

// Action creators are generated for each case reducer function
export const { increment, decrement, bulkUpdate, reset } = propertySlice.actions;

export const propertySliceReducer = propertySlice.reducer;

export const propertyState = (state) => state.property;