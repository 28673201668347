import { configureStore } from '@reduxjs/toolkit';
import { propertySliceReducer } from 'redux/slices/property';

export const store = configureStore({
    reducer: {
        property: propertySliceReducer
    },
    devTools: true,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    //     serializableCheck: false
    // })
});