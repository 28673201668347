import React, { useEffect, useState } from "react";
import { urlOf } from "../../dictionaries/customUrl";
import Cookies from "universal-cookie";
import { useRouter } from "next/router";
import Image from "next/image";
import NavLink from "../_NavLink/NavLink";
import { signOut, useSession } from "next-auth/react";

export default function DesktopMenu() {
  const [showUserImage, setShowUserImage] = useState(false);
  const { data: session } = useSession();
  const router = useRouter();
  let image = "/assets/img/user-ph.png";
  const handleLogout = () => {
    const cookies = new Cookies();
    cookies.remove("_zID");
    localStorage.removeItem("user");
    signOut();
    router.push("/");
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("user") || (session && session?.user)) {
        setShowUserImage(true);
      } else {
        setShowUserImage(false);
      }
    }
  }, [session]);

  return (
    <div className="container">
      <nav className="navbar navbar-light navbar-expand-lg mt-2">
        <div className="container-fluid">
          <div className="logo-container">
            <NavLink
              className="navbar-brand"
              href="/"
              alt={"Zeekeez.com"}
              title={"UAE's Property Portal | Zeekeez"}
            >
              <Image
                className="d-md-block"
                src={"assets/img/zeekeezlogo.svg"}
                alt={"Zeekeez Logo"}
                title={"Zeekeez Logo"}
                width={192}
                height={50}
              />
            </NavLink>
          </div>
          <button
            data-bs-toggle="collapse"
            className="navbar-toggler d-none"
            data-bs-target="#navcol-1"
          >
            <span className="visually-hidden">Toggle navigation</span>
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse mobildegizle" id="navcol-1">
            <ul className="navbar-nav mx-auto gap-3">
              <li className="nav-item">
                <NavLink
                  className={"nav-link"}
                  href={urlOf.residential.buy}
                  alt={"Buy Properties in UAE"}
                  title={"Buy Properties in UAE"}
                >
                  Buy
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={"nav-link"}
                  href={urlOf.residential.rent}
                  alt={"Rent Properties in UAE"}
                  title={"Rent Properties in UAE"}
                >
                  Rent
                </NavLink>
              </li>
              <div
                className="dropdown ms-3 nav-item"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <a
                  rel="nofollow,noindex"
                  title={"Luxury Properties in UAE"}
                  className="nav-link"
                  aria-expanded="false"
                  data-bs-toggle="dropdown"
                  href="#"
                >
                  Luxury Properties <i className="fa-solid fa-chevron-down"></i>
                </a>
                <div
                  className="dropdown-menu navbarprofilemenu"
                  style={{ minWidth: "15rem", padding: "1em" }}
                >
                  <li className="nav-item">
                    <NavLink
                      className={"nav-link"}
                      href={urlOf.luxury.buy}
                      alt={"Luxury Properties to Sale in UAE"}
                      title={"Luxury Properties to Sale in UAE"}
                    >
                      Luxury Properties for Sale
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={"nav-link"}
                      href={urlOf.luxury.rent}
                      alt={"Luxury Properties to Rent in UAE"}
                      title={"Luxury Properties to Rent in UAE"}
                    >
                      Luxury Properties for Rent
                    </NavLink>
                  </li>
                </div>
              </div>
              <div
                className="dropdown ms-3 nav-item"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <a
                  rel="nofollow,noindex"
                  title={"Commercial Properties in UAE"}
                  className="nav-link"
                  aria-expanded="false"
                  data-bs-toggle="dropdown"
                  href="#"
                >
                  Commercial <i className="fa-solid fa-chevron-down"></i>
                </a>
                <div
                  className="dropdown-menu navbarprofilemenu"
                  style={{ minWidth: "18rem", padding: "1em" }}
                >
                  <li className="nav-item">
                    <NavLink
                      className={"nav-link"}
                      href={urlOf.commercial.buy}
                      alt={"Commercial Properties to Sale in UAE"}
                      title={"Commercial Properties to Sale in UAE"}
                    >
                      Commercial Properties for Sale
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={"nav-link"}
                      href={urlOf.commercial.rent}
                      alt={"Commercial Properties to Rent in UAE"}
                      title={"Commercial Properties to Rent in UAE"}
                    >
                      Commercial Properties for Rent
                    </NavLink>
                  </li>
                </div>
              </div>
              <li className="nav-item">
                <NavLink
                  className={"nav-link"}
                  href={urlOf.static.mortgage}
                  alt={"UAE's Real Estate Guides"}
                  title={"UAE's Real Estate Guides"}
                >
                  Mortgages
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={"nav-link"}
                  href={urlOf.guides.list}
                  alt={"UAE's Real Estate Guides"}
                  title={"UAE's Real Estate Guides"}
                >
                  Guides
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={"nav-link"}
                  href={urlOf.blog.list}
                  alt={"UAE's Real Estate Blog"}
                  title={"UAE's Real Estate Blog"}
                >
                  Blog
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="d-flex align-items-center">
            {showUserImage ? (
              <div
                className=""
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <a
                  rel="nofollow,noindex"
                  title={"Profile"}
                  className="dropdown-toggle profiledropdown"
                  aria-expanded="false"
                  data-bs-toggle="dropdown"
                  href="#"
                >
                  {/* eslint-disable-next-line @next/next/no-img-element */}

                  <Image
                    alt="user"
                    src={image}
                    width={40}
                    height={40}
                    sizes="(max-width: 768px) 10vw, (max-width: 1200px) 50vw, 33vw"
                  />
                </a>
                <div className="dropdown-menu navbarprofilemenu">
                  <NavLink
                    rel="nofollow,noindex"
                    title={"Favorite Properties"}
                    className="dropdown-item"
                    href={urlOf.user.favorites}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                    >
                      <path d="M244 84L255.1 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 0 232.4 0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84C243.1 84 244 84.01 244 84L244 84zM255.1 163.9L210.1 117.1C188.4 96.28 157.6 86.4 127.3 91.44C81.55 99.07 48 138.7 48 185.1V190.9C48 219.1 59.71 246.1 80.34 265.3L256 429.3L431.7 265.3C452.3 246.1 464 219.1 464 190.9V185.1C464 138.7 430.4 99.07 384.7 91.44C354.4 86.4 323.6 96.28 301.9 117.1L255.1 163.9z" />
                    </svg>
                    Favorites
                  </NavLink>

                  <NavLink
                    rel="nofollow,noindex"
                    title={"Property Preferences"}
                    className="dropdown-item"
                    href={urlOf.user.profile}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="-32 0 512 512"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                    >
                      <path d="M272 304h-96C78.8 304 0 382.8 0 480c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32C448 382.8 369.2 304 272 304zM48.99 464C56.89 400.9 110.8 352 176 352h96c65.16 0 119.1 48.95 127 112H48.99zM224 256c70.69 0 128-57.31 128-128c0-70.69-57.31-128-128-128S96 57.31 96 128C96 198.7 153.3 256 224 256zM224 48c44.11 0 80 35.89 80 80c0 44.11-35.89 80-80 80S144 172.1 144 128C144 83.89 179.9 48 224 48z" />
                    </svg>
                    Profile
                  </NavLink>

                  <NavLink
                    rel="nofollow,noindex"
                    title={"My Acoount"}
                    className="dropdown-item"
                    href={urlOf.user.preferences}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                    >
                      <path d="M0 416C0 398.3 14.33 384 32 384H86.66C99 355.7 127.2 336 160 336C192.8 336 220.1 355.7 233.3 384H480C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H233.3C220.1 476.3 192.8 496 160 496C127.2 496 99 476.3 86.66 448H32C14.33 448 0 433.7 0 416V416zM192 416C192 398.3 177.7 384 160 384C142.3 384 128 398.3 128 416C128 433.7 142.3 448 160 448C177.7 448 192 433.7 192 416zM352 176C384.8 176 412.1 195.7 425.3 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H425.3C412.1 316.3 384.8 336 352 336C319.2 336 291 316.3 278.7 288H32C14.33 288 0 273.7 0 256C0 238.3 14.33 224 32 224H278.7C291 195.7 319.2 176 352 176zM384 256C384 238.3 369.7 224 352 224C334.3 224 320 238.3 320 256C320 273.7 334.3 288 352 288C369.7 288 384 273.7 384 256zM480 64C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H265.3C252.1 156.3 224.8 176 192 176C159.2 176 131 156.3 118.7 128H32C14.33 128 0 113.7 0 96C0 78.33 14.33 64 32 64H118.7C131 35.75 159.2 16 192 16C224.8 16 252.1 35.75 265.3 64H480zM160 96C160 113.7 174.3 128 192 128C209.7 128 224 113.7 224 96C224 78.33 209.7 64 192 64C174.3 64 160 78.33 160 96z" />
                    </svg>
                    Property Preferences
                  </NavLink>

                  <a
                    rel="nofollow,noindex"
                    title={"Log out"}
                    className="dropdown-item"
                    onClick={() => handleLogout()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                    >
                      <path d="M416 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c17.67 0 32 14.33 32 32v256c0 17.67-14.33 32-32 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c53.02 0 96-42.98 96-96V128C512 74.98 469 32 416 32zM342.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L242.8 224H32C14.31 224 0 238.3 0 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C355.1 266.1 355.1 245.9 342.6 233.4z" />
                    </svg>
                    Logout
                  </a>
                </div>
              </div>
            ) : (
              <div className="logregmotherdiv">
                <button
                  className="btn btn-outline-dark mybtn"
                  type="button"
                  data-bs-target="#login"
                  data-bs-toggle="modal"
                  id="loginButton"
                >
                  Sign in
                </button>
                <button
                  className="btn btn-dark mybtn"
                  type="button"
                  data-bs-target="#join"
                  data-bs-toggle="modal"
                >
                  Join
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}
