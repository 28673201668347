import React, { useEffect } from "react";
import { signIn, useSession } from "next-auth/react";
import Cookies from "universal-cookie";

const SigninButton = () => {
  const { data: session } = useSession();

  useEffect(() => {
    if (
      session &&
      session.accessToken &&
      session.user &&
      localStorage.getItem("user") === null
    ) {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        accessToken: session.accessToken,
      });
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://api.zeekeez.com/auth/v1/public/users/login-user-google",
        requestOptions
      )
        .then((response) =>
          response.json().then((res) => {
            const cookies = new Cookies();
            cookies.set("_zID", res.token, { path: "/", maxAge: 86400 * 7 });
            localStorage.setItem("imageUrl", res?.user.imageUrl);
            localStorage.setItem("firstName", res.user.firstName);
            localStorage.setItem("lastName", res.user.lastName);
            localStorage.setItem("user", JSON.stringify(res.user));
          })
        )

        .catch((error) => {});
    }
  }, [session]);

  return (
    <div className="mb-2 position-relative">
      <button
        aria-labelledby="Login with Google"
        className="btn btn-outline-dark w-100 mybtn"
        type="button"
        aria-label="Login with Google"
        onClick={() => {
          signIn("google");
        }}
      >
        Login With <span className="gilroybold">Google</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-12 0 512 512"
          width="1em"
          height="1em"
          fill="currentColor"
          className="loginsocialicon"
        >
          <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
        </svg>
      </button>
    </div>
  );
};

export default SigninButton;
