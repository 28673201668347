/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from "react";
import Cookies from "universal-cookie";
import { global } from "@/config/global";
import SigninButton from "../_google/SigninButton";

export default function LoginRegisterForms() {
  const [registerMessage, setRegisterMessage] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const email = useRef();
  const password = useRef();
  const remember = useRef();
  const errorMessage = useRef();

  const registerFirstName = useRef();
  const registerLastName = useRef();
  const registerEmail = useRef();
  const registerPassword = useRef();
  const registerPasswordConfirm = useRef();
  const termsAccept = useRef();

  const handleLogin = () => {
    let maxage = remember.current.checked ? 86400 * 7 : 3600;

    let body = {
      email: email.current.value,
      password: password.current.value,
    };

    let url = global.api.base + global.api.branch.auth.login.withEmail;

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        const cookies = new Cookies();
        cookies.set("_zID", response.token, { path: "/", maxAge: maxage });
        localStorage.setItem("imageUrl", response.user.imageUrl);
        localStorage.setItem("firstName", response.user.firstName);
        localStorage.setItem("lastName", response.user.lastName);
        localStorage.setItem("user", JSON.stringify(response.user));
        setShowModal(false);
        window.location.reload();
      })
      .catch((error) => {
        errorMessage.current.innerHTML =
          "Wrong email/password. Please try again.";
      });
  };

  const handleJoin = () => {
    if (termsAccept.current.checked) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        lastName: registerLastName.current.value,
        firstName: registerFirstName.current.value,
        email: registerEmail.current.value,
        password: registerPassword.current.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        global.api.base + "/auth/v1/public/users/create-with-email",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          window.location.reload();
        })
        .catch((error) => {});
    } else {
      setRegisterMessage("");
    }
  };

  return (
    <>
      <div
        className="modal fade logregmodal"
        role="dialog"
        tabIndex={-1}
        id="loggedin-notify"
      >
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <span className="modal-title">You're already logged in!</span>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade logregmodal"
        role="dialog"
        tabIndex={-1}
        id="join"
      >
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <span className="modal-title">Join Zeekeez!</span>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label className="form-label">First Name</label>
                  <input
                    className="form-control myinput"
                    type="text"
                    placeholder="John"
                    ref={registerFirstName}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Last Name</label>
                  <input
                    className="form-control myinput"
                    type="text"
                    placeholder="Doe"
                    ref={registerLastName}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input
                    className="form-control myinput"
                    type="email"
                    placeholder="john@doe.com"
                    ref={registerEmail}
                  />
                </div>
                <div className="mb-1">
                  <label className="form-label">Password</label>
                  <input
                    className="form-control myinput"
                    type="password"
                    placeholder="Create Password"
                    ref={registerPassword}
                  />
                  <small className="form-text">
                    At least 8 characters
                    <br />
                    At least 1 lowercase letter and 1 uppercase letter
                    <br />
                    At least 1 special character
                    <br />
                    Mix of letters and numbers
                    <br />
                  </small>
                </div>
                <div className="mb-3">
                  <input
                    className="form-control myinput"
                    type="password"
                    placeholder="Confirm password"
                    ref={registerPasswordConfirm}
                  />
                </div>
                <div className="ps-2 mb-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="formCheck-27"
                      ref={termsAccept}
                    />
                    <label className="form-check-label" htmlFor="formCheck-27">
                      I read and accept the{" "}
                      <a className="link-dark gilroybold" href="#">
                        Terms and C
                        <span style={{ color: "rgb(33, 37, 41)" }}>
                          onditions
                        </span>
                      </a>
                      .
                    </label>
                  </div>
                </div>
                <button
                  className="btn btn-dark w-100 mybtn"
                  type="button"
                  aria-label="Create Account"
                  onClick={handleJoin}
                >
                  Create Account
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade logregmodal"
        role="dialog"
        tabIndex={-1}
        id="login"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <span className="modal-title">Welcome to Zeekeez!</span>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input
                    className="form-control myinput"
                    type="email"
                    placeholder="john@doe.com"
                    ref={email}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <input
                    className="form-control myinput"
                    type="password"
                    placeholder="Enter Your Password"
                    ref={password}
                  />
                </div>
                <div className="ps-2 mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="formCheck-28"
                      ref={remember}
                    />
                    <label className="form-check-label" htmlFor="formCheck-28">
                      Remember me
                    </label>
                  </div>
                </div>
                <button
                  className="btn btn-dark w-100 mybtn mb-2"
                  type="button"
                  onClick={handleLogin}
                  aria-label="Login"
                >
                  Sign in
                </button>
                <label
                  className="w-100 text-center d-block text-decoration-none text-danger"
                  ref={errorMessage}
                ></label>
                <div className="mb-4">
                  <a
                    className="w-100 small text-center d-block text-decoration-none text-dark"
                    href="#"
                  >
                    I forgot my password
                  </a>
                </div>
                <div className="mb-4 position-relative">
                  <hr />
                  <span className="orspan">Or</span>
                </div>

                <div className="mb-2 position-relative">
                  <SigninButton />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
