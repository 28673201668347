import React from "react";
import DesktopMenu from "../_menus/Desktop";
import MobileMenu from "../_menus/Mobile";
import { checkSession } from "@/helpers/sessionHelper";

export default function Header() {
  return (
    <>
      <DesktopMenu />
      <MobileMenu />
    </>
  );
}
