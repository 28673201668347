import React from 'react'
import Header from '../_layout/Header'
import Footers from '../_layout/Footer'
import LoginRegisterForms from '../_modals/loginRegister'

export default function Layout({ children }) {
    return (
        <>
            <Header />
            {children}
            <LoginRegisterForms />
        </>
    )
}
