export default function mobileMenuopen() {

    var mymenulist = document.getElementById("mymenulist");
    var ortanavlinkk = document.getElementById("ortanavlinkk");

    if (!ortanavlinkk.classList.contains('active')) {

        document.getElementById('mymenu').style.bottom = "6rem";

        setTimeout(function () {
            document.getElementById('mymenu').style.height = "21rem";
            document.getElementById('mymenu').style.width = "60%";
            document.getElementById('mymenu').style.borderRadius = "1rem";
            ortanavlinkk.classList.add("active");
        }, 400);

        setTimeout(function () {
            mymenulist.classList.remove("hide");
            mymenulist.classList.add("show");
        }, 700);
    }

    else {

        mymenulist.classList.remove("show");
        mymenulist.classList.add("hide");
        ortanavlinkk.classList.remove("active");

        setTimeout(function () {
            document.getElementById('mymenu').style.height = "2rem";
            document.getElementById('mymenu').style.width = "2rem";
            document.getElementById('mymenu').style.borderRadius = "50%";
        }, 100);

        setTimeout(function () { document.getElementById('mymenu').style.bottom = "0%"; }, 500);

    }
}