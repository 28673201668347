function getBackendApi() {
  let backendApi = "https://api.zeekeez.com";
  switch (process.env.NODE_ENV) {
    case "staging":
      backendApi = "https://api-development.zeekeez.com";
      break;
    case "production":
      backendApi = "https://api.zeekeez.com";
      break;
    default:
      backendApi = "http://localhost:3999";
  }
  return backendApi;
}

export const global = {
  env: process.env.NODE_ENV || "development",
  // testUserToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjMxLCJlbWFpbCI6ImVraW5hdGFtdWFsbGFvZ2x1QGdtYWlsLmNvbSIsInR5cGUiOiJ1c2VyIiwiaWF0IjoxNjc1OTAwOTQyfQ._n_JomtCBhrYsCD9sH0x72DvHidM1PzKsK8sz-5MSU8",
  self:
    process.env.NODE_ENV === "production"
      ? "https://zeekeez.com"
      : "http://localhost:3002",
  api: {
    base: getBackendApi(),
    branch: {
      property: {
        self: "/properties/v1/public/properties",
        buy: "/properties/v1/public/properties?businessType=sale",
        rent: "/properties/v1/public/properties?businessType=rent",
      },

      blog: "/blogs/v1/public/posts",
      auth: {
        login: {
          withEmail: "/auth/v1/public/users/email-login",
          withFacebook: "",
          withGoogle: "",
        },
        register: {
          withEmail: "/auth/v1/public/users/email-register",
        },
        user: {
          // example user "email": "yevowit953@loongwin.com", "password" : "yevowit953"
          getFavs: "/properties/v1/user/properties/favorites",
          updateProfile: "/users/v1/user/profile",
          updatePassword: "/users/v1/user/password",
          get: "/users/v1/user/",
        },
      },
    },
  },
  siteMap: "/api/sitemap",
  langs: [
    { c: "en", n: "english", f: "", r: "en-US" }, // c => Code , n => Name , f => Flag, r => Region
  ],
  getImageUrl: (path) => {
    if (!path) {
      return null;
    }
    const isUrlRegex = /^https?:\/\//;
    return isUrlRegex.test(path) ? path : `${config.baseCDNUrl}${path}`;
  },
  getPropertyType: (url) => {
    const bedroomRegex = /([0-9]+)-bedroom-([a-z]+)-for-([a-z]+)/;
    const commercialLuxury = /([a-z]+)-([a-z]+)-for-([a-z]+)/;
    const commercialLuxuryBedroom =
      /([0-9]+)-bedroom-([a-z]+)-([a-z]+)-for-([a-z]+)/;
    const typeRegex = /([a-z]+)-for-([a-z]+)/;

    if (commercialLuxuryBedroom.test(url)) {
      const matches = url.match(commercialLuxuryBedroom);
      return { ...matches, type: "comLuxBedroom" };
    }

    if (bedroomRegex.test(url)) {
      const matches = url.match(bedroomRegex);
      return { ...matches, type: "bedroomIncluded" };
    }

    if (commercialLuxury.test(url)) {
      const matches = url.match(commercialLuxury);
      return { ...matches, type: "comLux" };
    }

    if (typeRegex.test(url)) {
      const matches = url.match(typeRegex);
      return { ...matches, type: "property" };
    }

    return url;
  },
};
